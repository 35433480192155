import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import "./SavedSearch.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import ToastPopup from '../../components/popup/ToastPopup';
import { coldWellCompany, sellerBroadcastApi } from '../../components/Api/ListingApi';
import SellerReviewPopup from "./sellerReviewPopup";
import dropdownbroad from "../../../static/images/dropdownbroad.png";
import { formatPrice } from '../../common/GenericFunction';


export default function CreateBroadCast(props) {
    const { broadcastAgent, toggleBroadcast, editDetails, broadcastDetails, refresh } = props;
    // console.log("broadcastDetails", editDetails)
    const userDetails = useSelector(state => state.user.userDetail);
    const locationData = editDetails.city;
    const cleanedLocation = (locationData && typeof locationData === 'string')
        ? locationData.replace(/, CA, USA/g, "")
        : '';

    const [messages, setMessages] = useState("");
    const [success, setSuccess] = useState(false);
    // const [inputValue, setInputValue] = useState('');
    const [inputValue1, setInputValue1] = useState('');
    const [valuesArray1, setValuesArray1] = useState([]);
    const [valuesArray, setValuesArray] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedValue1, setSelectedValue1] = useState('');
    const [customValue, setCustomValue] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [broastcastReview, setBroastcastReview] = useState(false);
    const [broadcastList, setBroadcastList] = useState([]);
    const [documentData, setDocumentData] = useState({
        proofOfFunds: false,
        mortgageApprovalLetter: false,
        cooperatingCommissionRequest: false,
        subject: '',
        description: '',
        message: ""
    });
    const [otherSpecialData, setOtherSpecialData] = useState([]);

    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    useEffect(() => {
        setSelectedOption(broadcastDetails.broadcast_type);
        setDocumentData({
            proofOfFunds: broadcastDetails.proof_of_funds,
            mortgageApprovalLetter: broadcastDetails.mortgage_approvel_letter,
            cooperatingCommissionRequest: broadcastDetails.cooperating_commission_request,
            subject: broadcastDetails.subject,
            description: broadcastDetails.description,
            message: broadcastDetails.message
        });
        setSelectedValue(broadcastDetails.cooperating_commission_offer);
        setValuesArray(broadcastDetails.bcc_client ?? []);  // Ensure secondary_area is an array        

        setValuesArray1(broadcastDetails.additional_emails ?? [])
        setCustomValue(broadcastDetails.cooperating_commission_request)
        const broadcastValue = broadcastDetails?.broadcast_value ?? [];
        if (Array.isArray(broadcastValue)) {
            setOtherSpecialData(broadcastValue);

        } else {
            setOtherSpecialData([]);
        }
    }, []);

    // console.log("selectedValue", selectedValue)

    useEffect(() => {
        broadcastBrokerage(page);
    }, [page]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop + 50 >= document.documentElement.scrollHeight && hasMore && !isLoading) {
                setPage(prevPage => prevPage + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isLoading, hasMore]);

    const toggleSuccess = () => setSuccess(p => !p);

    const handleSelectChange = (option) => {
        setSelectedOption(option);
        setDropdownOpen(false);
    };

    const handleDeleteValue = (index) => {
        const updatedValues = [...valuesArray1];
        updatedValues.splice(index, 1);
        setValuesArray1(updatedValues);
    };

    // const handleAddValues = () => {
    //     if (inputValue1 !== inputValue) {
    //         const newValues = inputValue.split(',').map(value => value.trim());

    //         let combinedValue = '';
    //         let remainingValues = [];

    //         if (newValues.length >= 3) {
    //             combinedValue = newValues.slice(0, 3).join(', ');
    //             remainingValues = newValues.slice(3);
    //         } else {
    //             combinedValue = newValues.join(', ');
    //         }

    //         const updatedArray = [...valuesArray, combinedValue];
    //         const uniqueValues = [...new Set(updatedArray.filter(Boolean))];

    //         setValuesArray(uniqueValues.concat(remainingValues));
    //         setInputValue('');
    //     } else if (inputValue !== "") {
    //         toast.error('Cannot set the same additional emails in Bcc Clients.', {
    //             position: "top-right",
    //             autoClose: 3000,
    //             closeOnClick: true,
    //         });
    //     }
    // };

    const handleAddValues1 = () => {
        if (cleanedLocation !== inputValue1) {
            const newValues = inputValue1.split(',').map(value => value.trim());

            let combinedValue = '';
            let remainingValues = [];

            if (newValues.length >= 3) {
                combinedValue = newValues.slice(0, 3).join(', ');
                remainingValues = newValues.slice(3);
            } else {
                combinedValue = newValues.join(', ');
            }

            const updatedArray = [...valuesArray1, combinedValue];
            const uniqueValues = [...new Set(updatedArray.filter(Boolean))];

            setValuesArray1(uniqueValues.concat(remainingValues));
            setInputValue1('');
        } else if (inputValue1 !== "") {
            toast.error('Cannot set the same area as both primary and additional service area.', {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });
        }
    };

    // const handleCheckboxChange = (event) => {
    //     const { name, checked } = event.target;
    //     setDocumentData(prevState => ({
    //         ...prevState,
    //         [name]: checked
    //     }));
    // };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDocumentData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // const handleCustomInputChange = (event) => {
    //     setCustomValue(event.target.value);
    // };

    const broadcastBrokerage = async (page) => {
        setIsLoading(true);
        let getParams = {
            office_name: userDetails.brokerage_name,
            page: 1,
            pagesize: 1000,
        }
        await coldWellCompany(getParams).then((res) => {
            const newBroadcasts = res.data.data;
            if (newBroadcasts.length === 0) {
                setHasMore(false);
            } else {
                setBroadcastList(prevList => [...prevList, ...newBroadcasts]);
            }
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }



    const handleBroatcast = async () => {
        let getParams = {
            "broadcast_type": userDetails.uid !== "ikt7BwdpqTWECm6V6QELYSiqnbo2" ? "my office" : selectedOption,
            "broadcast_value": selectedOption === "my office" ? userDetails.office_id : selectedOption === "my brokerage" ? otherSpecialData : selectedOption === "all agents" ? userDetails.office_id : "",
            "subject": documentData.subject,
            "cooperating_commission_request": (editDetails?.cooperating_commission_Percentage !== "0" && editDetails?.cooperating_commission_Percentage !== null) ? editDetails.cooperating_commission_Percentage : editDetails.cooperating_commission_Doller,
            "cooperating_commission_offer": (editDetails?.cooperating_commission_Percentage !== "0" && editDetails?.cooperating_commission_Percentage !== null) ? "percentage" : "flat rate",
            "agent_id": userDetails._id,
            "property_id": editDetails._id,
            "additional_emails": valuesArray1,
            "message": documentData.message
            // "bcc_client": valuesArray
        };

        try {
            const response = await sellerBroadcastApi(getParams);
            setMessages("Broadcasted Successfully");
            toggleBroadcast();
            toggleSuccess();
            // setTimeout(() => toggleSuccess(), 3000);
            setDocumentData({});
            setValuesArray([]);
            setSelectedValue("");
            setCustomValue("");
            setSelectedOption("");
            setSelectedValue1("");
            refresh();
            toast.success("Broadcasted Successfully", {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });
        } catch (error) {
            console.error(error);
        }
    };
    const handleClose = () => {
        toggleBroadcast();
    };

    const toggleBroadcastReview = () => {
        setBroastcastReview(prev => !prev);
    };

    // Function to toggle the id in the array


    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const [dropdownOpen2, setDropdownOpen2] = useState(false);

    const toggleDropdown2 = () => {
        setDropdownOpen2(!dropdownOpen2);
    };



    const handleOptionClick = (option) => {
        // console.log("option", option)
        setSelectedValue(option);
        setDropdownOpen2(false);
    };

    const options = ['Percentage', 'Flat Rate'];
    const brokerageOptions = ['my office', 'my brokerage', 'all agents'];
    const brokerageOptions2 = ['my office'];

    const [selectAll, setSelectAll] = useState(false);

    const onChangeotherSpecial = (id) => {
        const checkboxesCopy = [...otherSpecialData];
        const findIdx = checkboxesCopy.indexOf(id);
        if (findIdx > -1) {
            checkboxesCopy.splice(findIdx, 1);
        } else {
            checkboxesCopy.push(id);
        }
        setOtherSpecialData(checkboxesCopy);
    };

    const handleSelectAllChange = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);

        if (newSelectAll) {
            const allOfficeIDs = broadcastList.map((checkbox) => checkbox.OfficeID);
            setOtherSpecialData(allOfficeIDs);
        } else {
            setOtherSpecialData([]);
        }
    };

    return (
        <div>
            <ToastContainer />
            <SellerReviewPopup
                broastcastReview={broastcastReview}
                toggleBroadcastReview={toggleBroadcastReview}
                editDetails={editDetails}
                selectedOption={selectedOption}
                handleClose={handleClose}
                documentData={documentData}
                valuesArray={valuesArray}
                valuesArray1={valuesArray1}
                handleBroatcast={handleBroatcast}
                selectedValue={selectedValue}
                selectedValue1={selectedValue1}
                customValue={customValue}

            />
            <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />
            <Modal size="lg" show={broadcastAgent} onHide={handleClose} className="modelparent broadcast-popup" style={{ top: "10%" }}>
                <div className="PopUpBorderDiv closeIcons" style={{ border: "0px" }}>
                    <Modal.Header closeButton className="team-popup-margin1 teampopup-title savedSearchshare">
                        <Modal.Title className="savedSearch">
                            Select agent(s) you want to broadcast<br />{`"${editDetails.address}"`}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="team-popup-margin1 pb-0 broadcastWidth" >
                        {/* height: "600px", overflow: "auto" */}
                        <div>
                            <div className='position-relative savedSearchinput1 mb-3' style={{ width: "100%", margin: "0px", padding: "0.5rem 3rem", }}>
                                <h5 className='mt-4 savedSearch' style={{ color: "black" }}>Send to</h5>
                                <div className="custom-dropdown">
                                    {selectedOption !== "my office" ?
                                        <button className="dropbtn text-capitalize" onClick={toggleDropdown}>
                                            {selectedOption || 'Select'}
                                            <img src={dropdownbroad} alt='dropdownbroad' />
                                        </button> :
                                        <button className="dropbtn text-capitalize" onClick={toggleDropdown}>
                                            {selectedOption || 'Select'} <span style={{ color: "#323232", fontFamily: "dejavu sans", fontSize: "16px" }}>({userDetails.office_address})</span>
                                            <img src={dropdownbroad} alt='dropdownbroad' />
                                        </button>}
                                    {dropdownOpen && (
                                        <div className="dropdown-content">

                                            {brokerageOptions.map((option, index) => (
                                                <div
                                                    key={index}
                                                    className="dropdown-item text-capitalize"
                                                    onClick={() => handleSelectChange(option)}
                                                >
                                                    {option}
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                </div>
                                {selectedOption === "my brokerage" ?
                                    <div className="form-check row specialities" style={{ height: "250px", overflow: "auto" }}>
                                        <label className='AgentListSearch1 d-flex flex-row-reverse justify-content-end'>
                                            Select All
                                            <input
                                                type="checkbox"
                                                onChange={handleSelectAllChange}
                                                checked={selectAll}
                                                className="form-check-input inputStyle languageDetails borderDetails"
                                                style={{ position: "relative", top: "5px" }}
                                            />
                                        </label>
                                        {broadcastList.map((checkbox) => (
                                            <label className='AgentListSearch1 d-flex flex-row-reverse justify-content-end' key={checkbox.OfficeID} >
                                                {checkbox.OfficeName}, {checkbox.address}
                                                <input
                                                    type="checkbox"
                                                    onChange={() => onChangeotherSpecial(checkbox.OfficeID)}
                                                    checked={Array.isArray(otherSpecialData) && otherSpecialData.includes(checkbox.OfficeID)}
                                                    className="form-check-input inputStyle languageDetails borderDetails"
                                                    style={{ position: "relative", top: "5px" }}
                                                />
                                            </label>
                                        ))}
                                        <div className='resulttext'>
                                            {broadcastList.length === 0 && <div className='no-resultSell mt-5'> Result not found</div>}
                                        </div>
                                    </div> : null}

                                <div>
                                    <h5 className='mt-3 savedSearch' style={{ color: "black" }}>Additional Emails</h5>
                                    <div className='row' style={{ alignItems: "center" }}>
                                        <div className='col-md-9' >
                                            <div>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    value={inputValue1}
                                                    placeholder=""
                                                    name='additional_emails'
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1"
                                                    style={{
                                                        backgroundColor: 'white', borderRadius: "0px", border: "1px solid #ccc",
                                                        height: "60px", width: "100%", fontFamily: "dejavu sans"
                                                    }}
                                                    onChange={(e) => setInputValue1(e.target.value)}
                                                />

                                            </div>
                                        </div>
                                        <div className='col-md-1'>
                                            <Button className="addButtonBroad1 mt-0" onClick={handleAddValues1} >Add</Button>
                                        </div>
                                        <ul className='serviceArea'>
                                            {valuesArray1?.length !== 0 && valuesArray1?.map((value, index) => (
                                                <li key={index}>
                                                    {value}
                                                    <img src="/images/closeIconProfile.png" alt="logo" onClick={() => handleDeleteValue(index)} />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                </div>
                                {/* <div>
                                    <h5 className='mt-3 savedSearch' style={{ color: "black" }}>BCC Clients</h5>
                                    <div className='row' style={{ alignItems: "center" }}>
                                        <div className='col-md-9' >
                                            <div>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={inputValue}
                                                    placeholder=""
                                                    name='bcc_client'
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1"
                                                    style={{
                                                        backgroundColor: 'white', borderRadius: "0px", border: "1px solid #ccc",
                                                        height: "60px", width: "100%", fontFamily: "dejavu sans"
                                                    }}
                                                    onChange={(e) => setInputValue(e.target.value)}
                                                />

                                            </div>
                                        </div>
                                        <div className='col-md-1'>
                                            <Button className="addButton1 mt-0" onClick={handleAddValues}>Add</Button>
                                        </div>
                                        <ul className='serviceArea'>
                                            {valuesArray?.length !== 0 && valuesArray?.map((value, index) => (
                                                <li key={index}>
                                                    {value}
                                                    <img src="/images/closeIconProfile.png" alt="logo" onClick={() => handleDeleteValue(index)} />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                </div> */}
                                <div className="checkbox-container documaentCheckbox1 mt-3 mb-2" >
                                    <label>
                                        Message
                                    </label>
                                </div>

                                <div className="custom-dropdown">
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        value={documentData.message}
                                        name='message'
                                        onChange={handleInputChange}
                                        placeholder=""
                                        style={{ borderRadius: "0px", border: "1px solid #ccc", height: "60px", width: "100%" }}

                                    />

                                </div>
                                <div>
                                    <h5 className='mt-2 savedSearch' style={{ color: "black" }}>Subject</h5>
                                    <input
                                        type="text"
                                        className="form-control "
                                        value={documentData.subject}
                                        placeholder=""
                                        name='subject'
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        style={{ borderRadius: "0px", border: "1px solid #ccc", height: "60px", fontFamily: "dejavu sans" }}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <Button
                                        className="mb-3 mt-4 sharesSearchbut3"
                                        onClick={() => {
                                            if (selectedOption !== "") { toggleBroadcastReview() }
                                        }}>Review</Button>
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    );
}
